<template>
  <v-card class="ma-5">
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Гост карта, которую делаю</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-toolbar>
    <CardsTable :key="reloadCardsTable">
      <template v-slot:select="{ card }">
        <v-checkbox
          :value="card.id"
          v-model="selectedCards"
          v-if="Number(card.status) !== 2"
        />
      </template>
      <template v-slot:action="{ card }">
        <div style="display: flex">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="'/special-card/card/update?step=1&id=' + card.id"
                :disabled="Number(card.status) !== 1"
              >
                <v-icon color="primary" v-on="on">mdi-file-edit</v-icon>
              </v-btn>
            </template>
            <span>Редактировать</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon :to="'/special-card/card/copy?step=1&id=' + card.id">
                <v-icon color="primary" v-on="on">mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Копировать</span>
          </v-tooltip>
        </div>
      </template>
    </CardsTable>
    <v-card-actions>
      <!-- <v-btn
        color="default"
        :disabled="selectedCards.length === 0"
        @click="archiveCards"
        >
        В архив
      </v-btn> -->
      <v-spacer></v-spacer>
      <v-btn color="success" @click="createCard">Создать</v-btn>
    </v-card-actions>
    <v-btn @click="test" color="success">text</v-btn>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import CardsTable from "./components/CardsTable.vue";

export default {
  components: {
    CardsTable,
  },
  data: () => ({
    selectedCards: [],
    reloadCardsTable: 0,
    perPage: 5,
    S: 5,
    S1: 3,
  }),
  methods: {
    // archiveCards(item) {
    //   let url = '/gost-card/card/archive'
    //   this.$axios.post(url, { ids: this.selectedCards }).then(r => {
    //     this.reloadCardsTable = Date.now()
    //   }).catch(() => {})
    // },
    createCard() {
      //   this.$store.dispatch('specialCard/setStep', 1).then(() => {
      this.$router.push("/gost-card/card/create");
      //   })
    },
    test() {
      if (!( 1 <= this.S || this.S <= 4)) {
        console.log("data is not correct");
      }
      else('aa')
    },
  },
};
</script>
